import { PageProps } from "gatsby"
import { AssociationFilterType } from "src/constants/filters"

export const getParams = <T extends string>(
  location: PageProps["location"],
  keys: T[]
): [Record<T, string[]>, AssociationFilterType] => {
  const parts = location.search.substr(1).split("&")
  const params: Record<string, string[]> = {}
  let filterType: AssociationFilterType = AssociationFilterType.Activity
  parts.forEach((param) => {
    if (param) {
      const [key, val] = param.split("=")
      if (key === "type") {
        filterType = val === "Mission" ? AssociationFilterType.Mission : AssociationFilterType.Activity
      }
      if (val && keys.includes(key as T)) {
        params[key] = decodeURIComponent(val).split(",")
      }
    }
  })
  return [params, filterType]
}

export const getParam = (location: PageProps["location"], name: string): string => {
  const [params] = getParams(location, [name])
  const param = params[name]
  return param ? param[0] : null
}
