import { gql } from "@apollo/client"
import { ActivityFiltersSection } from "src/components/ActivityFiltersSection"
import AssociationList from "src/components/AssociationList"
import Loader from "src/components/Loader"
import { MissionFiltersSection } from "src/components/MissionFiltersSection"
import { useThemeFromDomainTheme } from "src/components/Theme"
import { COLORS, DOMAIN_ID, SIZES } from "src/constants"
import { AssociationFilters, AssociationFilterType, MissionRecurrenceType } from "src/constants/filters"
import { DataWithMeta, useClientQuery } from "src/helpers/apollo"
import { DomainThemeField, SearchAssociationFields } from "src/helpers/fragments"
import { getParams } from "src/helpers/search"
import useInfiniteScroll from "src/hooks/infiniteScroll"
import Layout from "src/layout"
import styled from "styled-components"

const SEARCH_QUERY = gql`
  query(
    $domainId: ID!
    $type: AssociationFilterType
    $tags: [ID!]
    $ages: [ID!]
    $days: [DaysOfWeek!]
    $schedules: [ID!]
    $page: Int!
    $perPage: Int!
    $mission_recurrences: [MissionRecurrence!]
    $mission_causes: [ID!]
    $mission_skills: [ID!]
  ) {
    allAssociations(
      type: $type
      domain: $domainId
      tags: $tags
      age_sub_groups: $ages
      days: $days
      schedule_groups: $schedules
      mission_recurrences: $mission_recurrences
      mission_causes: $mission_causes
      mission_skills: $mission_skills
      page: $page
      perPage: $perPage
    ) {
      ...SearchAssociationFields
    }
    meta: _allAssociationsMeta(
      type: $type
      domain: $domainId
      tags: $tags
      age_sub_groups: $ages
      days: $days
      schedule_groups: $schedules
      mission_recurrences: $mission_recurrences
      mission_causes: $mission_causes
      mission_skills: $mission_skills
    ) {
      count
    }
    allDomainSearchIntentTagCounts(domain: $domainId, age_sub_groups: $ages, days: $days, schedule_groups: $schedules) {
      tag {
        id
        name
      }
    }
    Domain(id: $domainId) {
      outlink_url
      legal_notice_url
      facebook_url
      twitter_url
      instagram_url
      ...DomainThemeField
    }
  }
  ${SearchAssociationFields}
  ${DomainThemeField}
`

export const ErrorMessage = styled.p`
  text-align: center;
  margin: 150px 20px;
  color: ${COLORS.red};
  font-size: ${SIZES.medium}px;
`
export const LoaderPlaceholder = styled.div`
  height: 300px;
`

interface Results extends DataWithMeta {
  allAssociations: Association[]
  allDomainSearchIntentTagCounts: DomainTagCount[]
  Domain: Domain
}

const SearchPage: GatsbyPage = ({ location }) => {
  const [params, filterType] = getParams(location, [
    "type",
    "tags",
    "ages",
    "days",
    "schedules",
    "mission_recurrences",
    "mission_causes",
    "mission_skills",
  ])

  const variables = {
    ...params,
    type: filterType,
    domainId: DOMAIN_ID,
  }
  const { data, error, loading, fetchMore } = useClientQuery<Results>(SEARCH_QUERY, variables, "allAssociations")

  useInfiniteScroll(fetchMore)

  const domain = data?.Domain
  const theme = useThemeFromDomainTheme(domain?.theme)

  if (!data || loading) {
    return null
  }

  const filters: AssociationFilters = {
    type: filterType,
    tagId: params.tags && params.tags[0],
    tagIds: params.tags,
    ageIds: params.ages,
    dayIds: params.days,
    scheduleIds: params.schedules,
    missionCauseIds: params.mission_causes,
    missionSkillIds: params.mission_skills,
    missionRecurrences: params.mission_recurrences as MissionRecurrenceType[],
  }

  return (
    <Layout theme={theme} domain={domain}>
      {filterType === AssociationFilterType.Activity && <ActivityFiltersSection {...filters} />}
      {filterType === AssociationFilterType.Mission && <MissionFiltersSection {...filters} />}

      {data && (
        <AssociationList
          hasFilters
          associations={data.allAssociations}
          tagCounts={data.allDomainSearchIntentTagCounts}
          loading={loading}
          wording={filterType === AssociationFilterType.Mission ? "mission de bénévolat" : "activité"}
          {...filters}
        />
      )}
      {error && <ErrorMessage>Une erreur est survenue. Veuillez recharger la page.</ErrorMessage>}
      {!data && <LoaderPlaceholder>{loading && <Loader />}</LoaderPlaceholder>}
    </Layout>
  )
}

export default SearchPage
