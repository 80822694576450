import { COLORS } from "src/constants"
import styled, { DefaultTheme, keyframes, ThemeProps } from "styled-components"

const anim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div<{ $nomargin?: boolean; $background?: string }>`
  color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  font-size: 11px;
  text-indent: -99999em;
  ${({ $nomargin }) => ($nomargin ? "margin: 0px auto;" : "margin: 55px auto;")}
  position: relative;
  width: 100px;
  height: 100px;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0) scale(0.5);
  border-radius: 50%;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    width: 60px;
    height: 110px;
    background: ${({ $background }) => $background || COLORS.white};
    border-radius: 10.2em 0 0 10.2em;
    top: -5px;
    left: -5px;
    transform-origin: 55px 55px;
    animation: ${anim} 2s infinite ease 1.5s;
  }
  &:after {
    border-radius: 50%;
    position: absolute;
    content: "";
    width: 70px;
    height: 110px;
    background: ${({ $background }) => $background || COLORS.white};
    border-radius: 0 10.2em 10.2em 0;
    top: -5px;
    left: 49px;
    transform-origin: 5px 55px;
    animation: ${anim} 2s infinite ease;
  }
`

export default Loader
