export const ActivityFields = `
  fragment ActivityFields on Activity {
    activity_schedules {
      id
      name
      day_name
      start_time
      end_time
    }
    addresses {
      complement
      street
      city
      zipcode
      full_address
      id
    }
    description_text
    pricing_text
    disabled
    absolute
    id
    age_min
    age_max
    tags {
      name
      id
    }
    title
    trainer_name
    association {
      id
      name
      absolute
    }
    customButtons {
      title
      url
    }
    contacts {
      id
      civility
      firstname
      lastname
      email
      phone
      title
      is_primary
    }
  }
`

export const MissionFields = `
  fragment MissionFields on Mission {
    addresses {
      street
      city
      zipcode
      full_address
      id
    }
    association {
      id
      name
      absolute
    }
    description_text
    disabled
    end_date
    absolute
    id
    recurrence
    causes {
      name
      id
    }
    skills {
      name
      id
    }
    start_date
    title
    customButtons {
      title
      url
    }
  }
`

export const AssociationFields = `
  fragment AssociationFields on Association {
    id
    createdAt
    description_text
    disabled
    absolute
    email
    facebook_url
    header_url
    instagram_url
    youtube_url
    linkedin_url
    logo_url
    name
    twitter_url
    updatedAt
    website_url
    phone
    main_tag {
      id
      name
    }
    contacts {
      id
      firstname
      lastname
      email
      phone
      title
    }
    addresses {
      id
      complement
      street
      city
      zipcode
      full_address
    }
    activities {
      ...ActivityFields
    }
    missions {
      ...MissionFields
    }
  }
`

export const SearchAssociationFields = `
  fragment SearchAssociationFields on Association {
    id
    description_text
    disabled
    absolute
    email
    header_url
    logo_url
    name
    main_tag {
      id
      name
    }
    activities {
      id
    }
    missions {
      id
    }
  }
`

export const DomainThemeField = `
  fragment DomainThemeField on Domain {
    theme {
      font
      text_color
      header_bg_url
      header_fg_color
      footer_bg_color
      footer_fg_color
      primary_color
      primary_text_color
    }
  }
`
