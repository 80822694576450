import { useLocation } from "@reach/router"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useEffect } from "react"
import { Button } from "src/components/Button"
import { Section } from "src/components/Grid"
import ModalSelect from "src/components/ModalSelect"
import { COLORS, LAYOUT } from "src/constants"
import {
  AssociationFilters,
  AssociationFilterType,
  FilterData,
  MissionRecurrenceType,
  MISSION_RECURRENCE,
  Option,
} from "src/constants/filters"
import { usePropsState } from "src/helpers/hooks"
import { getSearchPath } from "src/helpers/slug"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

export const Container = styled.section`
  background-image: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerBgUrl};
  background-color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  background-size: cover;
  color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerFgColor};
  text-align: center;
  padding: 2.5em 0;
  h1 {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerFgColor};
    font-size: 3em;
    font-weight: bold;
    line-height: 1em;
    margin: 0 20px;
  }
  h2 {
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerFgColor};
    font-size: 2em;
    font-weight: 100;
    margin: 10px 20px;
  }
  @media (max-width: ${LAYOUT.tablet - 1}px) {
    padding: 2em 0 1em;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
  }
`
export const Content = styled(Section)`
  padding: 10px;
  > div,
  > button {
    margin: 10px;
  }
  @media (min-width: ${LAYOUT.tablet}px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 30px auto 10px;
    > div {
      flex: 1;
      max-width: 300px;
    }
  }
`
export const FindButton = styled(Button)`
  padding: 14px 20px;
  &:hover {
    background-color: ${COLORS.grey};
    border-color: ${COLORS.grey};
  }
`

const DATA_QUERY = graphql`
  query {
    assolib {
      allDomains {
        mission_description
      }
      allTags(types: [MISSION_CAUSE, MISSION_SKILL]) {
        id
        name
        type
      }
    }
  }
`

interface Data {
  assolib: {
    allDomains: Domain[]
    allTags: Tag[]
  }
}

export const MissionFiltersSection = ({
  missionCauseIds: causeIds,
  missionSkillIds: skillIds,
  missionRecurrences,
}: Omit<AssociationFilters, "type">) => {
  const { assolib } = useStaticQuery<Data>(DATA_QUERY)

  const CAUSES: FilterData = {
    options: assolib.allTags.filter(({ type }) => type === "MISSION_CAUSE"),
  }
  const SKILLS: FilterData = {
    options: assolib.allTags.filter(({ type }) => type === "MISSION_SKILL"),
  }

  const getInitialRecurrence = () =>
    missionRecurrences ? MISSION_RECURRENCE.options.filter(({ id }) => missionRecurrences.includes(id)) : []
  const getInitialCauses = () => (causeIds ? CAUSES.options.filter(({ id }) => causeIds.includes(id)) : [])
  const getInitialSkills = () => (skillIds ? SKILLS.options.filter(({ id }) => skillIds.includes(id)) : [])

  const [recurrence, setRecurrence] = usePropsState<Option<MissionRecurrenceType>[]>(
    getInitialRecurrence,
    missionRecurrences
  )
  const [causes, setCauses] = usePropsState<Option[]>(getInitialCauses, causeIds)
  const [skills, setSkills] = usePropsState<Option[]>(getInitialSkills, skillIds)

  const destination = getSearchPath({
    type: AssociationFilterType.Mission,
    missionCauseIds: causes.map(({ id }) => id),
    missionSkillIds: skills.map(({ id }) => id),
    missionRecurrences: recurrence.map(({ id }) => id),
  })

  const location = useLocation()
  const current = location.pathname + location.search

  // TODO This break the single-responsibility principle. This component should not be aware and deal with paths.
  // We keep it ugly for now.
  useEffect(() => {
    if (destination === current) {
      return
    }

    if (destination !== "/search/?type=Mission" || /^\/(tag|search)\//.test(current)) {
      navigate(destination)
    }
  }, [destination]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = () => {
    if (current !== destination) {
      navigate(destination)
    }
  }

  return (
    <Container>
      <h1>Des associations à la recherche de bénévoles</h1>
      <h2>Engagez-vous !</h2>
      <Content as="div">
        <ModalSelect
          placeholder="Domaine d'action"
          title="Je sélectionne mes domaine d'action"
          data={[CAUSES]}
          values={[causes]}
          onChange={[setCauses]}
        />
        <ModalSelect
          placeholder="Domaine de compétence"
          title="Je sélectionne mes domaine de compétence"
          data={[SKILLS]}
          values={[skills]}
          onChange={[setSkills]}
        />
        <ModalSelect
          placeholder="Récurrence de la mission"
          title="Je sélectionne une récurrence"
          data={[MISSION_RECURRENCE]}
          values={[recurrence]}
          onChange={[setRecurrence]}
        />
        <FindButton onClick={handleSearch}>Trouver</FindButton>
      </Content>
    </Container>
  )
}
