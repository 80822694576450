import { useEffect } from "react"

const TRIGGER_DISTANCE_FROM_BOTTOM = 500 // distance in pixels below the fold to page bottom

const closeToBottom = () =>
  document.documentElement.offsetHeight - window.innerHeight - document.documentElement.scrollTop <
  TRIGGER_DISTANCE_FROM_BOTTOM

const useInfiniteScroll = (fetchMore: () => void) => {
  useEffect(() => {
    const handleScroll = () => {
      if (closeToBottom()) {
        fetchMore()
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [fetchMore])
}

export default useInfiniteScroll
