import { useLocation } from "@reach/router"
import AssociationCard from "src/cards/AssociationCard"
import { ButtonLink } from "src/components/Button"
import { Block, Grid, Odd, Section } from "src/components/Grid"
import Loader from "src/components/Loader"
import Selection from "src/components/Selection"
import Tags from "src/components/Tags"
import { COLORS, SIZES } from "src/constants"
import { AssociationFilters, AssociationFilterType } from "src/constants/filters"
import { inferAssociationFilterType } from "src/helpers/inferAssociationFilterType"
import styled from "styled-components"

const ResultSection = styled(Section)`
  padding: 20px 20px 40px;
`
const TagsSection = styled(ResultSection)`
  margin-bottom: 0;
`
const Strip = styled.div`
  padding: 25px;
  text-align: center;
  font-size: ${SIZES.medium}px;
`
const NoResultSection = styled(Section)`
  text-align: center;
  padding: 40px 20px 20px;
  font-size: ${SIZES.medium}px;
  font-weight: 700;
`
const NoResult = styled.div`
  text-align: center;
  font-size: ${SIZES.medium}px;
  font-weight: 700;
`

interface Props {
  associations: Association[]
  tagCounts?: DomainTagCount[]
  hasFilters?: boolean
  loading?: boolean
  wording?: string
}

const AssociationList: React.FC<Props & AssociationFilters> = ({
  associations,
  tagCounts,
  hasFilters,
  tagId,
  loading,
  wording = "activité",
  ...filters
}) => {
  const filterType = inferAssociationFilterType(filters)
  const filteredTagCounts = tagCounts && tagId ? tagCounts.filter(({ tag }) => tag.id !== tagId) : tagCounts
  const { pathname } = useLocation()
  const noFilterPathname = filterType === AssociationFilterType.Mission ? `${pathname}?type=Mission` : pathname

  const tags =
    ([AssociationFilterType.Activity, AssociationFilterType.Association].includes(filterType) &&
      filteredTagCounts &&
      filteredTagCounts.length > 0 && (
        <TagsSection>
          <h1>{hasFilters ? "Filtres les plus utilisés avec mes critères" : "Activités les plus recherchées"}</h1>
          <Tags
            list={filteredTagCounts}
            tagIds={filters.tagIds}
            ageIds={filters.ageIds}
            dayIds={filters.dayIds}
            scheduleIds={filters.scheduleIds}
            activityIds={filters.activityIds}
          />
        </TagsSection>
      )) ||
    undefined

  if (!associations.length) {
    return (
      <>
        <Strip>Aucune {wording} proposée dans votre ville</Strip>
        <NoResultSection>
          <ButtonLink to={noFilterPathname}>Réinitialiser mes critères de recherche</ButtonLink>
        </NoResultSection>
        {tags && <Odd>{tags}</Odd>}
        <Selection />
      </>
    )
  }

  const exact = associations.filter(({ absolute }) => absolute)
  const more = associations.filter(({ absolute }) => !absolute)

  const mapper = (association: Association) => (
    <Block key={association.id} $col={3}>
      <AssociationCard association={association} {...filters} />
    </Block>
  )

  return (
    <>
      <ResultSection>
        <h1>Résultats de recherche avec tous mes filtres</h1>
        {exact.length > 0 ? (
          <Grid>{exact.map(mapper)}</Grid>
        ) : (
          <>
            <Strip>Aucun résultat pertinent pour votre recherche</Strip>
            <NoResult>
              <ButtonLink to={noFilterPathname}>Réinitialiser mes critères de recherche</ButtonLink>
              {more.length > 0 && <p>ou consulter les résultats moins pertinents ci-dessous</p>}
            </NoResult>
          </>
        )}
      </ResultSection>
      <Odd>
        {more.length > 0 && (
          <ResultSection>
            <h1>Résultats de recherche avec une partie de mes filtres</h1>
            <Grid>{more.map(mapper)}</Grid>
          </ResultSection>
        )}
        {loading && <Loader $nomargin $background={COLORS.bright} />}
      </Odd>
      {more.length > 0 ? tags : <Odd>{tags}</Odd>}
    </>
  )
}

export default AssociationList
